import {useState} from 'react';
import {useQuery} from 'react-query';
import axios from 'services/axios';

export const QUERY = 'billing-report';

export async function getBillingReports(filter) {
  const {data} = await axios.get(`api/v2/admin/bill`, {
    params: {...filter},
  });

  return data;
}

export function useBillingReport(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery([QUERY, filter, defaultFilter], async () =>
    getBillingReports({...filter, ...defaultFilter}),
  );

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export async function getBillingReportPerSI(filter) {
  const {data} = await axios.get(`api/v2/admin/bill/per_si`, {
    params: {...filter},
  });

  return data;
}

export function useBillingReportBySI(defaultFilter = {}) {
  const [filter, setFilter] = useState({
    page: 1,
    search: null,
    sortBy: 'desc',
    sortOrder: 'created_at',
    limit: 10,
  });
  const fallback = [];
  const {
    data = fallback,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: [QUERY, filter, defaultFilter, 'billing-per-si'],
    queryFn: async () => getBillingReportPerSI({...filter, ...defaultFilter}),
    enabled: !!defaultFilter.shipping_information_id,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    filter,
    setFilter,
  };
}

export const fetchBillingReport = (billingReportId) => {
  return axios
    .get(`api/v2/admin/bill/${billingReportId}`)
    .then((res) => res.data);
};

export function useDetailBillingReport(billingReportId) {
  return useQuery({
    queryKey: billingReportId && [QUERY, billingReportId],
    queryFn: () => fetchBillingReport(billingReportId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!billingReportId,
  });
}

export const fetchInvoiceOptions = (filter = {}) => {
  return axios
    .get(`api/v2/admin/bill/select_shipping_invoices`, {params: filter})
    .then((res) => res.data);
};

export const fetchBillCollectible = (bill_ids) => {
  return axios
    .post(`api/v1/admin/bill/collectible`, {bill_ids})
    .then((res) => res.data);
};

export function useCollectibleBillingReport(bill_ids) {
  return useQuery({
    queryKey: [QUERY, 'bill-collectible', bill_ids],
    queryFn: () => fetchBillCollectible(bill_ids),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!bill_ids?.length,
  });
}

export const fetchBillInvoices = (customer_id) => {
  return axios
    .get(`api/v2/admin/bill/invoices`, {params: {customer_id}})
    .then((res) => res.data);
};

export function useInvoicesBillingReport(customer_id) {
  return useQuery({
    queryKey: [QUERY, 'bill-invoices', customer_id],
    queryFn: () => fetchBillInvoices(customer_id),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!customer_id,
  });
}

export async function downloadBillingReport(filter) {
  return new Promise(function (resolve, reject) {
    axios
      .get(`api/v2/admin/laporan-piutang/invoice`, {
        params: {...filter},
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/octet-stream',
        },
      })
      .then((response) => {
        const blob = new Blob(
          [response.data],
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
          }, //For excel file
        );

        const aEle = document.createElement('a'); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link

        aEle.href = href;
        aEle.download = 'Laporan-penagihan.xlsx'; // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
