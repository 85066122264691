/* eslint-disable no-unused-vars */
import React from 'react';
import BasicDialog from './BasicDialog';
import {
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import convertToCurrency from 'utils/convertToCurrency';
import {BasicSelect, BasicTextField} from 'components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  useSplitInvoiceBillingReport,
  useCollectibleBillingReport,
  useBillingReportBySI,
  useDetailBillingReport,
  fetchInvoiceOptions,
} from 'hooks/BillingReport';
import {showMessage, fetchError} from 'redux/actions/Common';
import {useDispatch} from 'react-redux';
import {useReceivablesReportInvoice} from 'hooks/ReceivablesReport';
import {useSelector} from 'react-redux';
import convertToDecimalCurrency from 'utils/convertToDecimalCurrency';
import {capitalizeFirstLetter} from 'utils/string';

const TableCellHead = ({children, minWidth}) => (
  <TableCell
    sx={{
      textTransform: 'uppercase',
      color: '#2155CD',
      fontWeight: 600,
      minWidth: minWidth,
    }}
  >
    {children}
  </TableCell>
);

TableCellHead.propTypes = {
  children: PropTypes.node,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

// const expectedPayload = {
//   bill_details: [
//     {
//       id: '',
//       first_billed_amount: '',
//       first_billed_to: '',
//       first_notes: '',
//       second_billed_amount: '',
//       second_billed_to: '',
//       second_notes: '',
//     },
//   ]
// };

export const DialogSplitInvoice = ({
  isOpen,
  onClose,
  invoiceNo,
  detailId,
  canCreateNew,
}) => {
  const dispatch = useDispatch();
  const [payload, setPayload] = React.useState([]);
  const {data: detail} = useDetailBillingReport(detailId);
  // const [invoiceId, setInvoiceId] = React.useState('');
  // const [invoiceCustomerId, setInvoiceCustomerId] = React.useState(null);

  const decimalStringToNumber = (value = '') => {
    return +String(value || '')
      .replace(/[^\d\-,]/g, '')
      .replace(',', '.');
  };

  const {mutate: mutateSplitInvoice, isLoading: isLoadingSplitInvoice} =
    useSplitInvoiceBillingReport(detail?.data?.id);
  const handleSplitInvoice = () => {
    mutateSplitInvoice(
      {
        bill_details: payload.map((item) => ({
          ...item,
          first_billed_amount: decimalStringToNumber(item.first_billed_amount),
          second_billed_amount: decimalStringToNumber(
            item.second_billed_amount,
          ),
          invoice_id: item.invoice_id || null,
          price: undefined,
        })),
      },
      {
        onSuccess: () => {
          dispatch(showMessage('Split Invoice Berhasil'));
          setPayload([]);
          onClose();
        },
        onError: (res) => {
          console.log(res);
          dispatch(
            fetchError(res?.response?.data?.message || 'Something Error'),
          );
        },
      },
    );
  };

  React.useEffect(() => {
    if (isOpen) {
      const detailPayload = [];
      detail?.data?.bill_details?.forEach((bill) => {
        bill?.items?.forEach((container) => {
          detailPayload.push({
            bill_id: bill.id,
            id: container.id,
            first_billed_amount: '',
            first_billed_to: detail?.data?.billed_to_customer_id,
            first_notes: '',
            second_billed_amount: '',
            second_billed_to: '',
            second_notes: '',
            invoice_id: null,
            price: container.price,
            type: detail?.data?.type,
          });
        });
      });
      setPayload([...detailPayload]);
    } else {
      setPayload([]);
    }
  }, [detail, isOpen]);

  const setBillDetails = React.useCallback(
    (id, name, value) => {
      setPayload((prev) =>
        prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              // [name]: decimalStringToNumber(value),
              [name]: value,
            };
          }

          return item;
        }),
      );
    },
    [payload],
  );

  const setCustomerBilledTo = React.useCallback(
    (id, name, value) => {
      setPayload((prev) =>
        prev.map((item) => {
          if (item.bill_id === id) {
            return {
              ...item,
              // [name]: decimalStringToNumber(value),
              [name]: value,
            };
          }

          return item;
        }),
      );
    },
    [payload],
  );

  const getValue = (id, name) => {
    const find = payload.find((item) => item.id === id);

    return find?.[name];
  };

  const getValueBilledTo = (id, name) => {
    const find = payload.find((item) => item.bill_id === id);

    return find?.[name];
  };

  const {data: collectibleData} = useCollectibleBillingReport([
    detail?.data?.id,
  ]);
  const [collectibleOptions, setCollectibleOptions] = React.useState([]);
  React.useEffect(() => {
    if (collectibleData?.status) {
      setCollectibleOptions(
        collectibleData?.data?.map((item) => ({
          label: item.name,
          value: item.id,
          detail: item,
        })),
      );
    }
  }, [collectibleData]);

  // const {data: invoicesData} = useInvoicesBillingReport(invoiceCustomerId);
  // const [invoicesOptions, setInvoicesOptions] = React.useState([
  //   {label: 'New Invoices', value: ''},
  // ]);
  // React.useEffect(() => {
  //   if (invoicesData?.status) {
  //     setInvoicesOptions([
  //       {label: 'New Invoices', value: ''},
  //       ...(invoicesData?.data?.map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       })) || []),
  //     ]);
  //   }
  // }, [invoicesData]);

  // React.useEffect(() => {
  //   const secondBilledTo = payload[0]?.second_billed_to;
  //   if (payload.every((item) => item.second_billed_to === secondBilledTo)) {
  //     setInvoiceCustomerId(secondBilledTo);
  //   }
  // }, [payload]);

  const [errors, setErrors] = React.useState([]);
  const inputError = React.useCallback(
    (value, id, name, max) => {
      const findError = errors.find((item) => item.id === id);

      if (value > max) {
        if (!findError) {
          setErrors((prev) => [
            ...prev,
            {
              id,
              name,
              text: `Maksimal ${convertToCurrency(max)}`,
            },
          ]);
        }
      } else {
        if (findError) {
          setErrors((prev) => {
            return prev.filter((item) => item.id !== id);
          });
        }
      }
    },
    [errors, payload],
  );

  const filterBillingReport = useSelector(
    ({filterBillingReport}) => filterBillingReport,
  );
  const {data} = useBillingReportBySI({...filterBillingReport});
  const [invoiceOptions, setInvoiceOptions] = React.useState([]);
  const setInvoiceByCustomer = React.useCallback(
    async (id, customerId, billId) => {
      const dataInvoice = await fetchInvoiceOptions({
        bill_id: billId,
        billed_to_customer_id: customerId,
      });

      if (dataInvoice?.data?.length) {
        let options = [];
        if (canCreateNew) {
          options = [
            {label: 'New Invoice', value: ''},
            ...(dataInvoice?.data?.map((item, index) => ({
              label: `${item.tmp_index}${item.tmp_invoice_no}`,
              value: item.id,
              billedTo: item.billed_to_customer_id,
            })) || []),
          ];
        } else {
          options = [
            ...(dataInvoice?.data?.map((item, index) => ({
              label: `${item.tmp_index}${item.tmp_invoice_no}`,
              value: item.id,
              billedTo: item.billed_to_customer_id,
            })) || []),
          ];
        }
        setInvoiceOptions((prev) => {
          const newData = prev.filter((item) => item.id !== id);
          newData.push({
            id,
            options: options.filter(
              (item) =>
                (item.billedTo === customerId && item.label !== invoiceNo) ||
                item.value === '',
            ),
          });

          return newData;
        });
      }
    },
    [data, invoiceNo],
  );

  const sumArray = (values = []) => {
    let total = 0;
    values.forEach((value) => (total += value));

    return total;
  };

  return (
    <BasicDialog
      isOpenDialog={isOpen}
      onClose={onClose}
      maxWidth='xl'
      title='Split Invoice'
    >
      <Grid container spacing={6}>
        <Grid item sm={12}>
          <TableContainer component={Paper} sx={{maxWidth: '100%'}}>
            <Table>
              <TableHead sx={{background: '#F3FDFF'}}>
                <TableRow>
                  <TableCellHead minWidth={200}>Keterangan</TableCellHead>
                  <TableCellHead minWidth={100}>Value</TableCellHead>
                  <TableCellHead>Tarif</TableCellHead>
                  <TableCellHead>Jumlah Tertagih</TableCellHead>
                  <TableCellHead>Ditagih Ke</TableCellHead>
                  <TableCellHead>Notes</TableCellHead>
                  <TableCellHead>Jumlah Tertagih</TableCellHead>
                  <TableCellHead>Ditagih Ke</TableCellHead>
                  <TableCellHead>Notes</TableCellHead>
                  <TableCellHead>Invoice</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row' colSpan={9}>
                    Order {invoiceNo}
                  </TableCell>
                </TableRow>
                {detail?.data?.bill_details?.map((item) => (
                  <>
                    <TableRow sx={{background: '#F3FDFF'}}>
                      <TableCell component='th' scope='row'>
                        Pengiriman - {item.name}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {
                          item.items?.filter(
                            (container) => container.type === 'shipping',
                          )?.length
                        }{' '}
                        Cont
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {convertToCurrency(item.price)}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {convertToCurrency(
                          sumArray(
                            item.items
                              ?.filter(
                                (container) => container.type === 'shipping',
                              )
                              .map((container) => container.price),
                          ),
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {detail?.data && !!collectibleOptions?.length && (
                          <BasicSelect
                            placeholder='Pilih Customer'
                            options={collectibleOptions}
                            value={getValueBilledTo(item.id, 'first_billed_to')}
                            onChange={(e) =>
                              setCustomerBilledTo(
                                item.id,
                                'first_billed_to',
                                e.target.value,
                              )
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'>
                        <BasicSelect
                          placeholder='Pilih Customer'
                          options={collectibleOptions}
                          value={getValueBilledTo(item.id, 'second_billed_to')}
                          onChange={(e) => {
                            setCustomerBilledTo(
                              item.id,
                              'second_billed_to',
                              e.target.value,
                            );
                            setInvoiceByCustomer(
                              item.id,
                              e.target.value,
                              item.items?.[0]?.bill_id,
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                      <TableCell component='th' scope='row'></TableCell>
                    </TableRow>
                    {item.items?.map((container, containerIndex) => (
                      <>
                        {container.type !==
                          item.items[containerIndex - 1]?.type &&
                          container.type !== 'shipping' && (
                            <TableRow sx={{background: '#E5E4E2'}}>
                              <TableCell>
                                <Typography fontSize={10} fontWeight={700}>
                                  {capitalizeFirstLetter(
                                    container.type?.replaceAll('_', ' '),
                                  )}{' '}
                                  - {item.name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {
                                  item.items?.filter(
                                    (containerItem) =>
                                      containerItem.type === container.type,
                                  )?.length
                                }{' '}
                                Cont
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {convertToCurrency(item.price)}
                              </TableCell>
                              <TableCell component='th' scope='row' colSpan={7}>
                                {convertToCurrency(
                                  sumArray(
                                    item.items
                                      ?.filter(
                                        (containerItem) =>
                                          containerItem.type === container.type,
                                      )
                                      .map(
                                        (containerItem) => containerItem.price,
                                      ),
                                  ),
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        <TableRow>
                          <TableCell component='th' scope='row'>
                            {container.no_container} - {container.no_seal}
                          </TableCell>
                          <TableCell component='th' scope='row'></TableCell>
                          <TableCell component='th' scope='row'></TableCell>
                          <TableCell component='th' scope='row'>
                            <BasicTextField
                              value={getValue(
                                container.id,
                                'first_billed_amount',
                              )}
                              onChange={(e) => {
                                let value = e.target.value.replace(
                                  /[^\d\-,]/g,
                                  '',
                                );
                                value = convertToDecimalCurrency(value);
                                setBillDetails(
                                  container.id,
                                  'first_billed_amount',
                                  value,
                                );
                                setBillDetails(
                                  container.id,
                                  'second_billed_amount',
                                  convertToDecimalCurrency(
                                    container.price -
                                      decimalStringToNumber(e.target.value),
                                  ),
                                );
                                inputError(
                                  decimalStringToNumber(e.target.value),
                                  container.id,
                                  'first_billed_amount',
                                  container.price,
                                );
                              }}
                              error={
                                !!errors?.find(
                                  (item) =>
                                    item.id === container.id &&
                                    item.name === 'first_billed_amount',
                                )
                              }
                              errorText={
                                errors?.find(
                                  (item) =>
                                    item.id === container.id &&
                                    item.name === 'first_billed_amount',
                                )?.text
                              }
                            />
                          </TableCell>
                          <TableCell component='th' scope='row'></TableCell>
                          <TableCell component='th' scope='row'>
                            <BasicTextField
                              value={getValue(container.id, 'first_notes')}
                              onChange={(e) =>
                                setBillDetails(
                                  container.id,
                                  'first_notes',
                                  e.target.value,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            <BasicTextField
                              value={getValue(
                                container.id,
                                'second_billed_amount',
                              )}
                              onChange={(e) => {
                                let value = e.target.value.replace(
                                  /[^\d\-,]/g,
                                  '',
                                );
                                value = convertToDecimalCurrency(value);
                                setBillDetails(
                                  container.id,
                                  'second_billed_amount',
                                  value,
                                );
                                setBillDetails(
                                  container.id,
                                  'first_billed_amount',
                                  convertToDecimalCurrency(
                                    container.price -
                                      decimalStringToNumber(e.target.value),
                                  ),
                                );
                                inputError(
                                  decimalStringToNumber(e.target.value),
                                  container.id,
                                  'second_billed_amount',
                                  container.price,
                                );
                              }}
                              error={
                                !!errors?.find(
                                  (item) =>
                                    item.id === container.id &&
                                    item.name === 'second_billed_amount',
                                )
                              }
                              errorText={
                                errors?.find(
                                  (item) =>
                                    item.id === container.id &&
                                    item.name === 'second_billed_amount',
                                )?.text
                              }
                            />
                          </TableCell>
                          <TableCell component='th' scope='row'></TableCell>
                          <TableCell component='th' scope='row'>
                            <BasicTextField
                              value={getValue(container.id, 'second_notes')}
                              onChange={(e) =>
                                setBillDetails(
                                  container.id,
                                  'second_notes',
                                  e.target.value,
                                )
                              }
                            />
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            <BasicSelect
                              options={
                                invoiceOptions?.find(
                                  (invoice) => invoice.id === item.id,
                                )?.options || []
                              }
                              value={getValue(container.id, 'invoice_id')}
                              onChange={(e) =>
                                setBillDetails(
                                  container.id,
                                  'invoice_id',
                                  e.target.value,
                                )
                              }
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid item sm={12}>
          <BasicSelect
            label='Pilihan Split'
            options={invoicesOptions}
            onChange={(e) => setInvoiceId(e.target.value)}
            value={invoiceId}
          />
        </Grid> */}
        <Grid item sm={12}>
          <Stack direction='row' justifyContent='flex-end' spacing={4}>
            <Button onClick={onClose} variant='contained' color='error'>
              Cancel
            </Button>
            <LoadingButton
              color='primary'
              variant='contained'
              loading={isLoadingSplitInvoice}
              type='button'
              disabled={!!errors?.length}
              onClick={handleSplitInvoice}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </BasicDialog>
  );
};

DialogSplitInvoice.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  detailId: PropTypes.string,
  invoiceNo: PropTypes.string,
  canCreateNew: PropTypes.boolean,
};

export default DialogSplitInvoice;
