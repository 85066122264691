export function capitalizeFirstLetter(string) {
  if (string) {
    return (
      string &&
      string.charAt(0) &&
      string.charAt(0).toUpperCase() + string.slice(1)
    );
  }

  return string;
}

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
}
