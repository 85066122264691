import axios from 'services/axios';
import {useMutation, useQueryClient} from 'react-query';
import {QUERY} from './useQuery';

export function useAddReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/laporan-piutang`, {
          ...values,
          id: undefined,
          truckingId: undefined,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useEditReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (formData) => {
      const {id} = formData;
      delete formData.id;
      return axios
        .put(`api/v1/admin/invoice/${id}`, formData)
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useDeleteReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    ({id}) =>
      axios
        .delete(`api/v1/admin/laporan-piutang/${id}`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useGenerateReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/laporan-piutang`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useMergeReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/laporan-piutang/merge`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useSubmitInvoiceReceivablesReport() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/invoice`, {
          ...values,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useDeleteReceivablesReportInvoice() {
  const queryClient = useQueryClient();

  return useMutation(
    ({id}) =>
      axios.delete(`api/v2/admin/invoice/${id}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useAddInvoiceEvidence() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/invoice_evidence`, values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useEditInvoiceEvidence() {
  const queryClient = useQueryClient();

  return useMutation(
    (values) =>
      axios
        .post(`api/v1/admin/invoice_evidence/${values.id}`, values.formData)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}

export function useDeleteInvoiceEvidence() {
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      axios
        .delete(`api/v1/admin/invoice_evidence/${id}`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY);
        queryClient.removeQueries(QUERY);
      },
    },
  );
}
