export default function convertToDecimalCurrency(angka) {
  if (angka) {
    let formated = String(angka);
    if (formated[formated.length - 1] === ',') {
      formated = formated.replaceAll(',', '.0');
      return `${new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      }).format(formated)},`;
    } else {
      formated = formated.replaceAll(',', '.');

      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 5,
      }).format(formated);
    }
  } else {
    return angka;
  }
}
